/* General Styling */
body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(135deg, #f0f4f8, #d9e3f0);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Login Container */
  .login-container {
    background-color: #fff;
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .login-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }
  
  /* Title Styling */
  .login-container h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  /* Form Styling */
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container input {
    margin-bottom: 20px;
    padding: 12px 16px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease;
  }
  
  .login-container input:focus {
    outline: none;
    border-color: #d14d72; /* Focus border color */
  }
  
  /* Button Styling */
  .login-container button {
    background-color: #d14d72;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .login-container button:hover {
    background-color: #b74363;
    transform: translateY(-2px);
  }
  
  /* Success Message */
  .login-container .success-message {
    font-size: 18px;
    color: #4CAF50;
    margin-bottom: 20px;
  }
  
  /* Error Message */
  .login-container .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .login-container {
      padding: 30px;
      max-width: 90%;
    }
  
    .login-container h2 {
      font-size: 24px;
    }
  
    .login-container input {
      font-size: 14px;
      padding: 10px 14px;
    }
  
    .login-container button {
      font-size: 14px;
    }
  
    .login-container .success-message,
    .login-container .error-message {
      font-size: 12px;
    }
  }
  
  /* Background styling to make it modern and responsive */
  body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(135deg, #f5c6da, #d14d72);
    opacity: 0.9;
  }
  
  /* Additional styling for laptops and larger screens */
  @media (min-width: 768px) {
    body {
      background: linear-gradient(135deg, #d14d72, #f9d7f9);
    }
  }