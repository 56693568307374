/* Global Reset to Prevent Horizontal Scrolling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

/* Make sure the body can scroll */
body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #fceff9, #fde4f7);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto; /* Allow vertical scrolling */
}

/* Ensure the content is scrollable */
.gift-form-container {
  padding-top: 150px;  /* Adjust this value based on your header's height */
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
  position: relative; /* Allow logo to be positioned inside this container */
}

/* Logo Styling */
.logo-container {
  position: absolute;
  top: 130px; /* Default: Adjust this to move the logo between the header and card */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; /* Ensure the logo appears above the card */
}

.logo {
  width: 120px; /* Default size */
  height: auto;
}

/* Media query to adjust the logo size and position for phones */
@media (max-width: 768px) {
  .logo {
    width: 90px; /* Smaller logo for phones */
  }
  .logo-container {
    top: 70px; /* Adjust top for smaller screens */
  }
}

/* Media query to adjust the logo size and position for very small phones */
@media (max-width: 480px) {
  .logo {
    width: 80px; /* Even smaller logo for very small screens */
  }
  .logo-container {
    top: 60px; /* Further adjust top for very small screens */
  }
}

/* Gift Form Styling */
.gift-form {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px 30px;
  max-width: 500px;
  width: 100%;
  margin: 80px auto 20px; /* Adjust the margin to account for the logo */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: right;
  overflow-y: auto; /* Allow scrolling inside the form if necessary */
  max-height: calc(100vh - 160px); /* Ensure the form is scrollable if content is long */
}

/* Form Input Group */
label {
  font-weight: 600;
  color: #444;
  display: block;
  margin-bottom: 10px;
}

input[type="text"], 
input[type="tel"], 
textarea {
  width: 100%;
  padding: 14px 18px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  font-size: 15px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus, 
input[type="tel"]:focus, 
textarea:focus {
  outline: none;
  border-color: #d14d72;
  background-color: #fff;
}

textarea {
  height: 120px;
  resize: none;
}

/* Submit Button */
button[type="submit"] {
  width: 100%;
  padding: 14px 22px;
  background-color: #d14d72;
  color: #fff;
  font-size: 17px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button[type="submit"]:hover {
  background-color: #b74363;
  transform: translateY(-5px);
}

/* Gift Selection Buttons */
.gift-select {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  gap: 20px;
}

.gift-option {
  width: 160px;
  background-color: #f9f9f9;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift-option img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-bottom: 2px solid #ddd;
}

.gift-option p {
  margin: 10px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #444;
}

.gift-option:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.15);
}

/* Highlight selected gift */
.gift-option.selected {
  border: 2px solid #d14d72;
  transform: translateY(-10px);
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.15);
}

/* Toast Notification */
.toast {
  position: fixed;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  background-color: #4CAF50;
  color: white;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  z-index: 1000;
  animation: fadeInOut 3s forwards;
  text-align: center;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .gift-form {
    padding: 20px;
  }

  label {
    font-size: 16px;
  }

  button[type="submit"] {
    font-size: 14px;
    padding: 12px 18px;
  }

  .gift-option {
    width: 130px;
  }

  .gift-option img {
    height: 130px;
  }

  .gift-option p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .gift-form-container {
    padding-top: 90px; 
  }
}

@media (max-width: 480px) {
  .gift-form-container {
    padding-top: 80px;
  }
}