/* General Styling */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #f4f7fc; /* Light background color for a clean look */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
}

/* Gift Card Container */
.gift-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
}

/* Card Styling */
.gift-card {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 16px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05); /* Subtle shadow for a modern look */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px; /* Space between logout button and card */
}

.gift-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1); /* Subtle hover effect */
}

/* Logout Button Styling */
.logout-button {
  padding: 14px 24px;
  border: none;
  border-radius: 50px;
  background-color: #d14d72;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Space between button and the card */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-button:hover {
  background-color: #b74363;
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Continue Logout Button Styling */
.logout-button:focus {
  outline: none;
}

/* Title Styling */
.gift-card h2 {
  font-size: 28px;
  font-weight: 700;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

/* Info Section */
.gift-card p {
  font-size: 16px;
  color: #666;
  line-height: 1.8;
  margin-bottom: 15px;
  width: 100%;
}

.gift-card p strong {
  color: #333;
  font-weight: 700;
}

/* Status Button Styling */
.gift-card button.activate,
.gift-card button.deactivate {
  padding: 14px 24px;
  border: none;
  border-radius: 50px; /* Modern rounded shape */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 60%; /* Buttons are larger and more prominent */
  max-width: 250px;
}

.gift-card button.activate {
  background-color: #28a745;
  color: #fff;
}

.gift-card button.deactivate {
  background-color: #dc3545;
  color: #fff;
}

.gift-card button.activate:hover,
.gift-card button.deactivate:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.gift-card button.activate:hover {
  background-color: #218838;
}

.gift-card button.deactivate:hover {
  background-color: #c82333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .gift-card {
    padding: 20px;
    max-width: 90%;
  }

  .gift-card h2 {
    font-size: 24px;
  }

  .gift-card p {
    font-size: 14px;
  }

  .logout-button,
  .gift-card button.activate,
  .gift-card button.deactivate {
    font-size: 14px;
    padding: 12px 18px;
  }
}

@media (max-width: 480px) {
  .gift-card {
    padding: 15px;
    max-width: 95%;
  }

  .gift-card h2 {
    font-size: 22px;
  }

  .gift-card p {
    font-size: 12px;
    line-height: 1.6;
  }

  .gift-card button.activate,
  .gift-card button.deactivate {
    font-size: 12px;
    padding: 10px 16px;
  }

  .logout-button {
    font-size: 12px;
    padding: 10px 16px;
  }
}