/* Global Reset to Prevent Horizontal Scrolling */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html, body {
    overflow-x: hidden;
    width: 100%;
  }
  
  /* Global Form Styling */
  body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(135deg, #fceff9, #fde4f7);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    direction: rtl;
  }
  
  .gift-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .gift-card {
    background-color: #d14d72; /* Dark pink color */
    color: #fff;
    border-radius: 16px;
    padding: 40px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease;
    position: relative;
    word-wrap: break-word; /* Ensure long words wrap to the next line */
    overflow-wrap: break-word;
  }
  
  .gift-card.open-animation {
    transform: scale(1.05);
  }
  
  .card-content {
    text-align: center;
    padding-top: 60px;
    word-wrap: break-word; /* Ensure long words wrap to the next line */
    overflow-wrap: break-word;
  }
  
  .logo {
    width: 150px;
    height: auto;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  
  .message-content {
    margin-top: 40px;
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word; /* Ensure long words break onto the next line */
    overflow-wrap: break-word;
    white-space: normal; /* Allow text to wrap */
    word-break: break-word; /* Break long words into the next line */
}

.message-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.message-content p {
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold; /* Make sender's name bold */
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  margin-bottom: 10px;
  white-space: normal; /* Ensure text wraps to the next line */
  overflow: hidden;
}


.open-gift-btn {
  width: 100%;
  padding: 14px 22px;
  background-color: #b74363;
  color: #fff;
  font-size: 17px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.open-gift-btn:hover {
  background-color: #a13b57;
  transform: translateY(-5px);
}

/* Reveal Gift Modal */
.gift-reveal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.gift-box {
  background-color: #fff;
  padding: 30px;
  border-radius: 16px;
  text-align: center;
  width: 80%;
  max-width: 500px;
  animation: revealAnimation 0.5s ease-out forwards;
}

.gift-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-top: 20px;
}

.close-gift-btn {
  width: 100%;
  padding: 14px;
  margin-top: 20px;
  background-color: #d14d72;
  color: #fff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 600;
}

.close-gift-btn:hover {
  background-color: #b74363;
}

/* Animation for revealing the gift */
@keyframes revealAnimation {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .gift-card {
    padding: 20px;
  }

  .open-gift-btn {
    font-size: 14px;
    padding: 12px 18px;
  }

  .gift-box {
    padding: 20px;
  }
}