/* Global Reset to Prevent Horizontal Scrolling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

/* Header Styling */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #d14d72; /* Dark pink background */
  padding: 15px 30px;
  display: flex;
  justify-content: space-between; /* Push Instagram to the left and logo text to the right */
  align-items: center; /* Vertically center the content */
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: padding 0.3s ease;
}

/* Logo Styling (Right-Aligned) */
.logo-text {
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  text-align: right; /* Align the text to the right */
}

/* Instagram Icon Styling (Left-Aligned) */
.instagram-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 24px;
  transition: color 0.3s ease;
}

.instagram-link:hover {
  color: #f9c9d3;
}

.instagram-icon {
  font-size: 28px;
}

/* Responsive Header for Smaller Screens */
@media (max-width: 768px) {
  .header {
    padding: 12px 20px;
  }

  .logo-text {
    font-size: 24px;
  }

  .instagram-icon {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px 15px;
  }

  .logo-text {
    font-size: 22px;
  }

  .instagram-icon {
    font-size: 22px;
  }
}